import React from "react";
// Route Specific
import { Routes, Route } from "react-router-dom";
import ScrollTopBehaviour from "../components/ScrollTopBehaviour";

// All HOME PAGE ROUTES
import EventOrganizer from "../views/all-home-pages/EventOrganizer";
import DocLanding from "../views/all-home-pages/DocLanding";
import DocSignatureLanding from "../views/all-home-pages/DocSignatureLanding";
import Home from "../views/all-home-pages/Home";
import CustomerSupport from "../views/all-home-pages/CustomerSupport";
import ProductLanding from "../views/all-home-pages/ProductLanding";
import ProductLandingDark from "../views/all-home-pages/ProductLandingDark";
import NoteTakingLanding from "../views/all-home-pages/NoteTakingLanding";
import VideoEditorLanding from "../views/all-home-pages/VideoEditorLanding";
import AppointmentScheduling from "../views/all-home-pages/AppointmentScheduling";
import MeetingRoom from "../views/all-home-pages/MeetingRoom";
import DayPass from "../views/all-home-pages/DayPass";
import WebsiteBuilderLanding from "../views/all-home-pages/WebsiteBuilderLanding";
import HotSeat from "../views/all-home-pages/HotSeat";
import Career from "../views/all-home-pages/Career";
import CorporateService from "../views/all-home-pages/CorporateService";
import ComingSoon from "../views/all-home-pages/ComingSoon";
import PrivateOffice from "../views/all-home-pages/PrivateOffice";
import CareerList from "../views/all-home-pages/CareerList";
import Clients from "../views/all-home-pages/Clients";
import Testimonial from "../views/inner-pages/pages/contact/Testimonial";

// All INNER PAGES ROUTES START FROM BELLOW

// PAGES DROPDOWN ALL ROUTES
// Team inner pages
import Team1 from "../views/inner-pages/pages/team/Team1";
import Team2 from "../views/inner-pages/pages/team/Team2";
import Team3 from "../views/inner-pages/pages/team/Team3";
import Team4 from "../views/inner-pages/pages/team/Team4";
import Team5 from "../views/inner-pages/pages/team/Team5";
import Team6 from "../views/inner-pages/pages/team/Team6";
import TeamDetailsV1 from "../views/inner-pages/pages/team/TeamDetailsV1";
import TeamDetailsV2 from "../views/inner-pages/pages/team/TeamDetailsV2";
import Faq from "../views/inner-pages/pages/Faq";
import FaqDetails from "../views/inner-pages/pages/FaqDetails";

// Contact us inner pages
import ContactCustomerSupport from "../views/inner-pages/pages/contact/ContactCustomerSupport";
import ContactEventOrganizer from "../views/inner-pages/pages/contact/ContactEventOrganizer";
import ContactProjectManagement from "../views/inner-pages/pages/contact/ContactProjectManagement";
import ContactUs from "../views/inner-pages/pages/contact/ContactUs";
import ContactDocumentation from "../views/inner-pages/pages/contact/ContactDocumentation";

// About us inner pages
import AboutCustomerSupport from "../views/inner-pages/pages/about/AboutCustomerSupport";
import AboutEventOrganizer from "../views/inner-pages/pages/about/AboutEventOrganizer";
import AboutProjectManagement from "../views/inner-pages/pages/about/AboutProjectManagement";
import AboutDocumentation from "../views/inner-pages/pages/about/AboutDocumentation";
import About1 from "../views/inner-pages/pages/about/About1";
// Pricing inner pages
import PricingCustomerSupport from "../views/inner-pages/pages/pricing/PricingCustomerSupport";
import PricingEventOrganizer from "../views/inner-pages/pages/pricing/PricingEventOrganizer";
import PricingProjectManagement from "../views/inner-pages/pages/pricing/PricingProjectManagement";

// FEATURES DROPDOWN ALL ROUTES
import Login from "../views/inner-pages/features/miscellaneous/Login";
import SignUp from "../views/inner-pages/features/miscellaneous/SignUp";
import TermsConditions from "../views/inner-pages/features/miscellaneous/TermsConditions";
import SolutionMangement from "../views/inner-pages/features/SolutionMangement";
import ProductCustomerSupport from "../views/inner-pages/features/ProductCustomerSupport";
import FeaturesCustomerSupport from "../views/inner-pages/features/FeaturesCustomerSupport";

// SERVICE PAGES ROUTES
import ServiceV1 from "../views/inner-pages/service/ServiceV1";
import ServiceV2 from "../views/inner-pages/service/ServiceV2";
import ServiceV3 from "../views/inner-pages/service/ServiceV3";
import ServiceV4 from "../views/inner-pages/service/ServiceV4";
import ServiceDetails from "../views/inner-pages/service/ServiceDetails";

// DOCS DROPDOWN ALL ROUTES
import DocFullWidth from "../views/inner-pages/docs/DocFullWidth";
import DocFullWidthBanner from "../views/inner-pages/docs/DocFullWidthBanner";
import DocBox from "../views/inner-pages/docs/DocBox";
import DocBoxWithBanner from "../views/inner-pages/docs/DocBoxWithBanner";
import Changelog from "../views/inner-pages/docs/Changelog";

// PORTFOLIO DROPDOWN ALL ROUTES
import PortfolioV1 from "../views/inner-pages/portfolio/PortfolioV1";
import PortfolioV2 from "../views/inner-pages/portfolio/PortfolioV2";
import PortfolioV3 from "../views/inner-pages/portfolio/PortfolioV3";
import PortfolioV4 from "../views/inner-pages/portfolio/PortfolioV4";
import PortfolioV5 from "../views/inner-pages/portfolio/PortfolioV5";
import PortfolioDetailsV1 from "../views/inner-pages/portfolio/PortfolioDetailsV1";

// BLOGS DROPDOWN ALL ROUTES
import Blog from "../views/inner-pages/blog-pages/Blog";
import BlogPage from "../views/inner-pages/blog-pages/BlogPage";
import BlogV1 from "../views/inner-pages/blog-pages/BlogV1";
import BlogV2 from "../views/inner-pages/blog-pages/BlogV2";
import BlogV3 from "../views/inner-pages/blog-pages/BlogV3";
import BlogV4 from "../views/inner-pages/blog-pages/BlogV4";
import BlogV5 from "../views/inner-pages/blog-pages/BlogV5";
import BlogV6 from "../views/inner-pages/blog-pages/BlogV6";
import BlogDetails from "../views/inner-pages/blog-pages/BlogDetails";

// Not Found Page
import NotFound from "../views/NotFound";

import DedicatedDesk from "../views/all-home-pages/DedicatedDesk";
import VirtualOffice from "../views/all-home-pages/VirtualOffice";
import Cart from "../views/inner-pages/e-commerce/Cart";
import Checkout from "../views/inner-pages/e-commerce/Checkout";
import ProductDetails from "../views/inner-pages/e-commerce/ProductDetails";
import BlogV7 from "../views/inner-pages/blog-pages/BlogV7";
import BlogV8 from "../views/inner-pages/blog-pages/BlogV8";
import BlogV9 from "../views/inner-pages/blog-pages/BlogV9";
import BlogV10 from "../views/inner-pages/blog-pages/Blogv10";
import BlogV11 from "../views/inner-pages/blog-pages/Blogv11";
import BlogV12 from "../views/inner-pages/blog-pages/BlogV12";
import BlogV13 from "../views/inner-pages/blog-pages/BlogV13";
import BlogV14 from "../views/inner-pages/blog-pages/BlogV14";
import BlogV15 from "../views/inner-pages/blog-pages/BlogV15";
import BlogV16 from "../views/inner-pages/blog-pages/BlogV16";
import BlogV17 from "../views/inner-pages/blog-pages/BlogV17";
import BlogV18 from "../views/inner-pages/blog-pages/BlogV18";
import BlogV19 from "../views/inner-pages/blog-pages/BlogV19";
import BlogV20 from "../views/inner-pages/blog-pages/BlogV20";
import BlogV21 from "../views/inner-pages/blog-pages/Blogv21";
import BlogV22 from "../views/inner-pages/blog-pages/Blogv22";
import BlogV23 from "../views/inner-pages/blog-pages/Blogv23";
import BlogV24 from "../views/inner-pages/blog-pages/Blogv24";
import BlogV25 from "../views/inner-pages/blog-pages/Blogv25";
import BlogV26 from "../views/inner-pages/blog-pages/Blogv26";
import BlogV27 from "../views/inner-pages/blog-pages/Blogv27";
import BlogV28 from "../views/inner-pages/blog-pages/Blogv28";
import BlogV29 from "../views/inner-pages/blog-pages/Blogv29";
import BlogV30 from "../views/inner-pages/blog-pages/Blogv30";
import BlogV31 from "../views/inner-pages/blog-pages/BlogV31";
import BlogV32 from "../views/inner-pages/blog-pages/BlogV32";
import BlogV33 from "../views/inner-pages/blog-pages/BlogV33";
import BlogV34 from "../views/inner-pages/blog-pages/BlogV34";
import BlogV35 from "../views/inner-pages/blog-pages/BlogV35";
const AllRoutes = () => {
  return (
    <>
      <ScrollTopBehaviour />
      <Routes>
        <Route path="/" element={<Home></Home>} />
        <Route path="/doc-landing" element={<DocLanding />} />
        <Route path="/doc-signature" element={<DocSignatureLanding />} />
        <Route path="/Home" element={<Home></Home>} />
        <Route path="/customer-support" element={<CustomerSupport />} />
        <Route path="/product-landing" element={<ProductLanding />} />
        <Route path="/eo" element={<EventOrganizer />} />
        <Route path="/product-landing-dark" element={<ProductLandingDark />} />
        <Route path="/note-taking-landing" element={<NoteTakingLanding />} />
        <Route path="/video-editor-landing" element={<VideoEditorLanding />} />
        <Route
          path="/appointment-scheduling"
          element={<AppointmentScheduling />}
        />
        <Route path="/meeting-room" element={<MeetingRoom />} />
        <Route path="/day-pass" element={<DayPass />} />
        <Route path="/website-builder" element={<WebsiteBuilderLanding />} />
        <Route path="/dedicated-desk" element={<DedicatedDesk />} />
        <Route path="/virtual" element={<VirtualOffice />} />
        <Route
          path="/private-office"
          element={<PrivateOffice></PrivateOffice>}
        />
        <Route path="/hot-seat" element={<HotSeat></HotSeat>} />
        <Route path="/career" element={<Career></Career>} />
        <Route path="/career-list" element={<CareerList />} />
        <Route path="/clients" element={<Clients />} />
        <Route path="/bussiness" element={<CorporateService />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/product-details" element={<ProductDetails />} />
        <Route path="/coming-soon" element={<ComingSoon />} />

        {/* Pages Dropdown Routes */}
        <Route path="/team-1" element={<Team1 />} />
        <Route path="/team-2" element={<Team2 />} />
        <Route path="/team-3" element={<Team3 />} />
        <Route path="/team-4" element={<Team4 />} />
        <Route path="/team-5" element={<Team5 />} />
        <Route path="/team-6" element={<Team6 />} />
        <Route path="/team-details-v1" element={<TeamDetailsV1 />} />
        <Route path="/team-details-v2" element={<TeamDetailsV2 />} />
        {/* faq inner pages */}
        <Route path="/faq" element={<Faq />} />
        <Route path="/faq-details" element={<FaqDetails />} />
        {/* contact us inner pages */}
        <Route path="/contact-cs" element={<ContactCustomerSupport />} />
        <Route path="/contact-eo" element={<ContactEventOrganizer />} />
        <Route path="/contact-pm" element={<ContactProjectManagement />} />
        <Route path="/contact-doc" element={<ContactDocumentation />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/testimonial" element={<Testimonial />} />

        {/* about us inner pages */}
        <Route path="/about-cs" element={<AboutCustomerSupport />} />
        <Route path="/about-eo" element={<AboutEventOrganizer />} />
        <Route path="/about-pm" element={<AboutProjectManagement />} />
        <Route path="/about-doc" element={<AboutDocumentation />} />
        <Route path="/about" element={<About1 />} />
        {/* pricing inner pages */}
        <Route path="/pricing-cs" element={<PricingCustomerSupport />} />
        <Route path="/pricing-eo" element={<PricingEventOrganizer />} />
        <Route path="/pricing-pm" element={<PricingProjectManagement />} />

        {/* Feature Dropdown Routes */}
        {/* <Route path="/login" element={<Login />} />
        <Route path="/SignUp" element={<SignUp />} /> */}
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/solution-management" element={<SolutionMangement />} />
        <Route
          path="/product-customer-support"
          element={<ProductCustomerSupport />}
        />
        <Route
          path="/features-customer-support"
          element={<FeaturesCustomerSupport />}
        />

        {/* Service inner pages */}
        <Route path="/service-v1" element={<ServiceV1 />} />
        <Route path="/service-v2" element={<ServiceV2 />} />
        <Route path="/service-v3" element={<ServiceV3 />} />
        <Route path="/service-v4" element={<ServiceV4 />} />
        <Route path="/service-details" element={<ServiceDetails />} />

        {/* inner pages Docs   */}
        <Route path="/doc-full-width" element={<DocFullWidth />} />
        <Route path="/doc-full-width-banner" element={<DocFullWidthBanner />} />
        <Route path="/doc-box" element={<DocBox />} />
        <Route path="/doc-box-with-banner" element={<DocBoxWithBanner />} />
        <Route path="/changelog" element={<Changelog />} />

        {/* inner Portfolio pages Dropdown   */}
        <Route path="/classic-style" element={<PortfolioV1 />} />
        <Route path="/grid-two-col" element={<PortfolioV2 />} />
        <Route path="/grid-three-col" element={<PortfolioV3 />} />
        <Route path="/gallery-slider" element={<PortfolioV4 />} />
        <Route path="/grid-single" element={<PortfolioV5 />} />
        <Route path="/portfolio-details-v1" element={<PortfolioDetailsV1 />} />
        {/* inner Blog pages Dropdown   */}
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog-page" element={<BlogPage />} />
        <Route
          path="/coworkingspaceTraditionaloffices"
          element={<coworkingspaceTraditionaloffices />}
        />
        <Route path="/blog-v1" element={<BlogV1 />} />
        <Route path="/blog-v2" element={<BlogV2 />} />
        <Route path="/blog-v3" element={<BlogV3 />} />
        <Route path="/blog-v4" element={<BlogV4 />} />
        <Route path="/blog-v5" element={<BlogV5 />} />
        <Route path="/blog-v6" element={<BlogV6 />} />
        <Route path="/blog-v7" element={<BlogV7 />} />
        <Route
          path="/coworking-vs-traditional-office-which-is-right-for-your-business"
          element={<BlogV8 />}
        />
        <Route
          path="/Shaping-the-Future:-Coworking-in-the-New-Work-Era"
          element={<BlogV9 />}
        />
        <Route
          path="/unlocking-the-advantages-of-virtual-offices-for-bussiness"
          element={<BlogV10 />}
        />
        <Route
          path="/benefits-of-collaborative-workspaces"
          element={<BlogV11 />}
        />
        <Route path="/iot-and-smart-office-solution" element={<BlogV12 />} />
        <Route
          path="/flexible-workspaces-adapting-to-changing-work-dynamics"
          element={<BlogV13 />}
        />
        <Route
          path="/emerging-technologies-shaping-the-future-of-workspace"
          element={<BlogV14 />}
        />
        <Route
          path="/how-to-maximize-your-productivity-in-a-shared-office-space"
          element={<BlogV15 />}
        />
        <Route
          path="/building-a-strong-community-in-shared-office-space"
          element={<BlogV16 />}
        />
        <Route
          path="/innovative-shared-office-space-designs-to-boost-creativity"
          element={<BlogV17 />}
        />
        <Route
          path="/the-rise-of-virtual-offices-in-the-modern-workplace"
          element={<BlogV18 />}
        />

        <Route
          path="/implementing-and-managing-a-virtual-office"
          element={<BlogV19 />}
        />

        <Route
          path="/the-benefits-of-virtual-coworking-offices-for-freelancers"
          element={<BlogV20 />}
        />

        <Route
          path="/tools-and-technologies-that-power-virtual-coworking-offices"
          element={<BlogV21 />}
        />
        <Route
          path="/the-role-of-virtual-coworking-offices-in-remote-work-trends"
          element={<BlogV22 />}
        />
        <Route
          path="/organizing-virtual-events-and-meetups-in-coworking-spaces"
          element={<BlogV23 />}
        />
        <Route
          path="/productivity-tips-for-working-in-a-shared-workspace"
          element={<BlogV24 />}
        />
        <Route
          path="/how-to-boost-team-collaboration-in-shared-workspaces"
          element={<BlogV25 />}
        />
        <Route
          path="/the-role-of-technology-in-modern-collaborative-workspaces"
          element={<BlogV26 />}
        />
        <Route
          path="/designing-a-professional-workspace-for-remote-teams"
          element={<BlogV27 />}
        />
        <Route
          path="/balancing-privacy-and-collaboration-in-professional-workspaces"
          element={<BlogV28 />}
        />
        <Route
          path="/why-coworking-spaces-are-perfect-for-freelancers"
          element={<BlogV29 />}
        />
        <Route
          path="/how-to-manage-time-effectively-in-a-coworking-space"
          element={<BlogV30 />}
        />
        <Route
          path="/key-features-to-look-for-in-a-coworking-space"
          element={<BlogV31 />}
        />
        <Route
          path="/balancing-work-and-life-in-a-coworking-space"
          element={<BlogV32 />}
        />
        <Route
          path="/rethinking-the-workplace-the-rise-of-shared-workspaces"
          element={<BlogV33 />}
        />
        <Route
          path="/maximizing-efficiency-in-a-shared-workspace"
          element={<BlogV34 />}
        />
        <Route
          path="/why-startups-and-freelancers-are-flocking-to-shared-workspaces"
          element={<BlogV35 />}
        />
        <Route path="/blog-details" element={<BlogDetails />} />
        <Route path="*" element={<NotFound />} />

        {/* NotFound Route */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default AllRoutes;
