import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
// import emailjs from '@emailjs/browser';
import axios from 'axios';
import { useState, useRef} from "react";
// import AnchorLink from 'react-anchor-link-smooth-scroll';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import dayjs from 'dayjs';
import ReCAPTCHA from "react-google-recaptcha"



function ContactForm() {
    const [Spaceinterested, setSpaceinterested] = useState('');
    const [name, setname] = useState("");
    const [phone, setphone] = useState("");
    const [email, setemail] = useState("");
    const [company, setcompany] = useState("");
    // const [natureofbussiness, setnatureofbussiness] = useState("");
    // const [city, setcity] = useState("");
    // const [anyothers, setanyothers] = useState("");
    // const [duration, setduration] = useState("");
    // const [startfrom, setstartfrom] = useState(null);
    // const [noofseats, setnoofseats] = useState("");
    // const [noofcabins, setnoofcabins] = useState("");
    const [description, setdescription] = useState("");
    const [formErrors, setFormErrors] = useState({});
    const [submissionStatus, setSubmissionStatus] = useState(null);

    const [spaceInterestedError, setSpaceInterestedError] = useState(false);

    const [spaceInterestedError1, setSpaceInterestedError1] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [checkboxError, setCheckboxError] = useState('');
    const captchaRef = useRef(null)
    const [captchaToken, setCaptchaToken] = useState(null);
    const [captchaerror, setcaptchaerror] = useState('');


    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const handleChange = (event) => {
        setSpaceinterested(event.target.value);
    };
    const handlename = (event) => {
        setname(event.target.value);
    };
    const handlephone = (event) => {
        setphone(event.target.value);
    };
    const handleemail = (event) => {
        setemail(event.target.value);
    };
    const handlecompany = (event) => {
        setcompany(event.target.value);
    };
    // const handlenatureofbussiness = (event) => {
    //     setnatureofbussiness(event.target.value);
    // };
    // const handlecity = (event) => {
    //     setcity(event.target.value);
    // };
    // const handleanyothers = (event) => {
    //     setanyothers(event.target.value);
    // };
    // const handleduration = (event) => {
    //     setduration(event.target.value);
    // };
    // const handlestartfrom = (event) => {
    //     setstartfrom(event.target.value);
    // };
    // const handlenoofseats = (event) => {
    //     setnoofseats(event.target.value);
    // };
    // const handlenoofcabins = (event) => {
    //     setnoofcabins(event.target.value);
    // };
    const handledescription = (event) => {
        setdescription(event.target.value);
    };

    const validateForm = () => {

        const errors = {};

        if (!isChecked) {
            errors.checkbox = 'Checkbox must be checked';
            setCheckboxError('Checkbox must be checked');
        } else {
            setCheckboxError('');
        }

        if (name.trim() === "") {
            errors.name = "Name is required";
        }
        if (Spaceinterested === "") {
            setSpaceInterestedError("Space Interested is required");
            errors.spaceInterested = "Space Interested is required";
        } else {
            setSpaceInterestedError("");
        }


        if (phone.trim() === "") {
            errors.phone = "Phone number is required";
        } else if (!/^[0-9]{10}$/.test(phone)) {
            errors.phone = "Invalid phone number";
        }
        if (email.trim() === "") {
            errors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = "Invalid email format";
        }
        if (company.trim() === "") {
            errors.company = "Company is required";
        }
        // if (natureofbussiness.trim() === "") {
        //     errors.natureofbussiness = "Nature of business is required";
        // }
        // if (city.trim() === "") {
        //     errors.city = "City is required";
        // }
        // if (duration.trim() === "") {
        //     errors.duration = "Duration is required";
        // }
        // if (anyothers.trim() === "") {
        //     errors.anyothers = "Any Others is required";
        // }
        // if (noofseats.trim() === "") {
        //     errors.noofseats = "No Of Seats is required";
        // } else if (isNaN(noofseats)) {
        //     errors.noofseats = "No Of Seats must be a number";
        // } else if (noofseats <= 0) {
        //     errors.noofseats = "No Of Seats must be a positive number";
        // }
        // if (noofcabins.trim() === "") {
        //     errors.noofcabins = "No Of Private Cabins is required";
        // } else if (isNaN(noofcabins)) {
        //     errors.noofcabins = "No Of Private Cabins must be a number";
        // } else if (noofcabins <= 0) {
        //     errors.noofcabins = "No Of Private Cabins must be a positive number";
        // }
        // if (description.trim() === "") {
        //     errors.description = "Description is required";
        //     setSpaceInterestedError1("true")
        // }
        // else {
        //     setSpaceInterestedError1("false")
        // }

        // if (startfrom.trim() === "") {
        //     errors.startfrom = "Start From is required";
        // }
        if (captchaerror === '' && captchaToken ===null) {
            setcaptchaerror("Captcha is required")
        }

        


        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const RecaptchaChange = (value) =>{
        const token =captchaRef.current.getValue();
        setCaptchaToken(token)
        setcaptchaerror('')
        console.log('sss',token)
        //     setcaptchaerror('')
        // captchaRef.current.getResponse().then(res => {
        //     setCaptchaToken(res)
        //     setcaptchaerror('')
        // })

    }

    const handleSubmit = (event) => {
   
        event.preventDefault();
     

        if (validateForm()) {

            if (captchaToken !==null){
                const config = {
                    headers:{
                    'accept':' application/json',
                    'api-key':'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',
                    'content-type':'application/json'
                    }
                  };
                  const url = "https://api.brevo.com/v3/smtp/email";
                  
                //   const data ={
                    
                //         "sender":{  
                //            "name":"Client Enquiry",
                //            "email":"admin@innovspace.com"
                //         },
                //         "to":[  
                //            {  
                        
                //             "email": "admin@innovspace.com",
                //             "name": "Innovspace"
                //            }
                //         ],
                //         "subject":"Innovspace Enquiry Form",
                //         "htmlContent":`<html><head></head><body>
                //         <h3>Contact Us Page Form</h3>
                //         <p>Name: ${name}</p>
                //         <p>Phone No: ${phone}</p>
                //         <p>Email: ${email}</p>
                //         <p>Company: ${company}</p>
                //         <p>Nature Of Bussiness: ${natureofbussiness}</p>
                //         <p>Space Intersted In:${Spaceinterested}</p>
                //         <p>City: ${city}</p>
                //         <p>Duration: ${duration}</p>
                //         <p>Start From: ${startfrom}</p>
                //         <p>No Of Seats: ${noofseats}</p> 
                //         <p>No Of Cabins: ${noofcabins}</p>
                //          <p>Description: ${description}</p>
                //         </body></html>`
                     
                //   }    

                const data ={
                    
                    "sender":{  
                       "name":"Client Enquiry",
                       "email":"admin@innovspace.com"
                    },
                    "to":[  
                       {  
                    
                        "email": "admin@innovspace.com",
                        "name": "Innovspace"
                       }
                    ],
                    "subject":"Innovspace Enquiry Form",
                    "htmlContent":`<html><head></head><body>
                    <h3>Contact Us Page Form</h3>
                    <p>Name: ${name}</p>
                    <p>Phone No: ${phone}</p>
                    <p>Email: ${email}</p>
                    <p>Company: ${company}</p>
                    <p>Space Intersted In:${Spaceinterested}</p>
                     <p>Description: ${description}</p>
                    </body></html>`
                 
              }    
    
                  axios.post(url, data, config)
                      .then(response => {
                          console.log("data",response.data);
                        })
                        .catch(error => {
                          console.log("error",error);
                        });
                  
                setSubmissionStatus('success');
                console.log("Form submitted successfully!");
                setname("");
                setIsChecked(false);
                setphone("");
                setemail("");
                // setcity("");
                setcompany("");
                // setnatureofbussiness("");
                setSpaceinterested("");
                // setanyothers("");
                // setduration("");
                // setstartfrom(null);
                // setnoofcabins("");
                // setnoofseats("");
                setdescription("");
                setcaptchaerror("");
                setCaptchaToken(null)
                captchaRef.current.reset();
                setTimeout(() => {
                    setSubmissionStatus(null);
                    setCaptchaToken(null);
                }, 5000);
            }
            // Perform form submission or further processing
          
            
        } else {
            console.log("Form validation failed!");
        }
    };

    return (

        <div >
           
            <div class="container mt-20">
                {/* <h4>Talk To An Expert</h4>
        <p>  Tell Us Your Requirements and Out Workspace Expert 
          willReach out to You at earliest.
        </p> */}

                <form onSubmit={handleSubmit}>
                    <div class="row">
                        <div class="form-group col-md-6">

                            <TextField id="outlined-basic" label="Name" variant="outlined" onChange={handlename} value={name}
                                error={!!formErrors.name}
                                helperText={formErrors.name}
                                size="small"
                                name="name"
                                fullWidth

                            />

                        </div>
                        <div class="form-group col-md-6">
                            {/* 
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { mt:1,mb:1, width: '100%' },
                }}
                noValidate
                autoComplete="off"
              > */}
                            <TextField id="outlined-basic" label="Phone Number" variant="outlined" onChange={handlephone} value={phone}
                                error={!!formErrors.phone}
                                helperText={formErrors.phone}
                                size="small"
                                fullWidth
                                name="phone"
                                className='md-mt-20'

                            />
                            {/* </Box>
      */}
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-6">
                            {/* <Box
                component="form"
                sx={{
                  '& > :not(style)': { mt:1,mb:1, width: '100%' },
                }}
                noValidate
                autoComplete="off"
              > */}
                            <TextField id="outlined-basic" label="Email Id" variant="outlined" onChange={handleemail} value={email}
                                error={!!formErrors.email}
                                helperText={formErrors.email}
                                size="small"
                                name="email"
                                fullWidth
                                style={{ marginTop: "20px" }}
                            />
                            {/* </Box> */}
                        </div>
                        <div class="form-group col-md-6">
                            {/* 
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { mt:1,mb:1, width: '100%' },
                }}
                noValidate
                autoComplete="off"
              > */}
                            <TextField id="outlined-basic" label="Company" variant="outlined" onChange={handlecompany} value={company}
                                error={!!formErrors.company}
                                helperText={formErrors.company}
                                size="small"
                                fullWidth
                                name="company"
                                style={{ marginTop: "20px" }}
                            />
                            {/* </Box> */}
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-6">

                            {/* <Box
                component="form"
                sx={{
                  '& > :not(style)': {mt:1,mb:1,  width: '100%' },
                }}
                noValidate
                fullWidth
                autoComplete="off"
              > */}
                            {/* <TextField id="outlined-basic" label="Nature Of Business" variant="outlined" onChange={handlenatureofbussiness} value={natureofbussiness}
                                error={!!formErrors.natureofbussiness}
                                helperText={formErrors.natureofbussiness}
                                size="small"
                                fullWidth
                                name="nature"
                                style={{ marginTop: "20px" }}
                            /> */}
                            {/* </Box> */}
                        </div>
                        <div class="form-group col-md-6">
                            {/* 
            <Box
                component="form"
                sx={{
                  '& > :not(style)': {mt:1,mb:1,  width: '100%' },
                }}
                noValidate
                autoComplete="off"
              > */}
                            {/* <TextField id="outlined-basic" label="City" variant="outlined" onChange={handlecity} value={city}
                                error={!!formErrors.city}
                                helperText={formErrors.city}
                                size="small"
                                fullWidth
                                name="city"
                                style={{ marginTop: "20px" }}
                            /> */}
                            {/* </Box> */}
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-12">
                            {/* <FormControl fullWidth  sx={{
                  '& > :not(style)': { mt:1, width: '100%' ,zIndex:"999"},
                }}>
        <InputLabel id="demo-simple-select-helper-label">Space Interested</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={Spaceinterested}
          label="Space Interested"
          onChange={handleChange}
          error={!!spaceInterestedError}
          helperText={spaceInterestedError}
          className='show'
        >
          <div  className='show'>
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={"Hot Seat"}>Private Space</MenuItem>
          <MenuItem value={"Virtual Office"}>Virtual Office</MenuItem>
          <MenuItem value={"Dedicated Desk"}>Dedicated Desk</MenuItem>
          <MenuItem value={"Private Office"}>Private Office</MenuItem>
          <MenuItem value={"Executive Cabin"}>Executive Cabin</MenuItem>
          <MenuItem value={"Team Office"}>Team Office</MenuItem>
          <MenuItem value={"Meeting Room"}>Meeting Room</MenuItem>
          <MenuItem value={"Conference Room"}>Conference Room</MenuItem>
          <MenuItem value={"Training Room"}>Training Room</MenuItem>
          <MenuItem value={"Interview Room"}>Interview Room</MenuItem>
          <MenuItem value={"Event Space"}>Event Space</MenuItem>
        </div>
        </Select>
        
        {spaceInterestedError && (
  <FormHelperText error>{spaceInterestedError}</FormHelperText>
       )}
      </FormControl> */}


                            <select style={{ color: "#74808e" }} onChange={handleChange} value={Spaceinterested}
                                // error={!!spaceInterestedError}
                                helperText={spaceInterestedError}
                                name="dropdown"
                                className={spaceInterestedError ? "error form-select form-select-lg " : 'selecttag form-select form-select-lg '}
                            >
                                <option value="" disabled selected >Space Interested In</option>
                                <option value="Private Space">Private Space</option>
                                <option value="Virtual Office">Virtual Office</option>
                                <option value="Dedicated Desk">Dedicated Desk</option>
                                {/* <option value="Private Office">Private Office</option>   ______form in contact us page*/}
                                <option value="Executive Cabin">Executive Cabin</option>
                                <option value="Team Office">Team Office</option>
                                <option value="Meeting Room">Meeting Room</option>
                                <option value="Conference Room">Conference Room</option>
                                <option value="Training Room">Training Room</option>
                                <option value="Interview Room">Interview Room</option>
                                <option value="Others">Others</option>
                            </select>
                            {/* <select class="form-select form-select-lg " aria-label=".form-select-lg example">
  <option selected>Open this select menu</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select> */}
                            {spaceInterestedError && (
                                <FormHelperText error style={{ marginLeft: "10px" }}>{spaceInterestedError}</FormHelperText>
                            )}
                        </div>
                        {/* <div class="form-group col-md-6">
                            <TextField id="outlined-basic" label="Any Others" variant="outlined" onChange={handleanyothers} value={anyothers}
                                error={!!formErrors.anyothers}
                                helperText={formErrors.anyothers}
                                size="small"
                                name="anyothers"
                                fullWidth
                                style={{ marginTop: "20px" }}
                            />
                        </div> */}
                    </div>
                    {/* <div class="row"> */}
                        {/* <div class="form-group col-md-6"> */}
                        {/* <select style={{ color: "#74808e" }} onChange={handleduration} value={duration}
                                // error={!!spaceInterestedError}
                                helperText={formErrors.duration}
                                name="dropdown"
                                className={formErrors.duration ? "error form-select form-select-lg " : 'selecttag form-select form-select-lg '}> */}
                                {/* <option value="" disabled selected >Duration</option> */}
                                {/* <option value="1 Month">1 Month</option> */}
                                {/* <option value="2 Months">2 Months</option> */}
                                {/* <option value="3 Months">3 Months</option> */}
                                {/* <option value="4 Months">4 Months</option> */}
                                {/* <option value="5 Months">5 Months</option> */}
                                {/* <option value="6 Months">6 Months</option> */}
                                {/* <option value="7 Months">7 Months</option> */}
                                {/* <option value="8 Months">8 Months</option> */}
                                {/* <option value="9 Months">9 Months</option> */}
                                {/* <option value="10 Months">10 Months</option> */}
                                {/* <option value="11 Months">11 Months</option> */}
                                {/* <option value="12 Months">12 Months</option> */}
                                {/* <option value="More than 12 Months">More than 12 Months</option> */}
                        {/* </select> */}
                        {/* </div> */}

                        {/* <div class="form-group col-md-6" style={{marginTop:"22px"}}> */}

                            {/* <Box
                             component="form"
                             sx={{
                             '& > :not(style)': { mt:1,mb:1, width: '100%' },
                             }}
                             noValidate
                             autoComplete="off"
                             > */}                      
                            
                             

                            {/* <TextField id="outlined-basic" label="Start From" variant="outlined" onChange={handlestartfrom} value={startfrom}
                                error={!!formErrors.startfrom}
                                helperText={formErrors.startfrom}
                                size="small"
                                name="startfrom"
                                fullWidth
                                style={{ marginTop: "20px" }}
                            /> */}

                            {/* <DatePicker label="Start From" slotProps={{ textField: { size: 'small'} }}
                            value={startfrom} onChange={(newValue) => setstartfrom(newValue)}
                             size="small"
                             />  */}
                            
                            {/* </Box> */}
                        {/* </div> */}
                    {/* </div> */}
                    {/* <div class="row"> */}
                        {/* <div class="form-group col-md-6"> */}
                            {/* 
            <Box
                component="form"
                sx={{
                  '& > :not(style)': { mt:1,mb:1, width: '100%' },
                }}
                noValidate
                autoComplete="off"
              > */}
                            {/* <TextField id="outlined-basic" label="No of Seats" variant="outlined" onChange={handlenoofseats} value={noofseats}
                                error={!!formErrors.noofseats}
                                helperText={formErrors.noofseats}
                                size="small"
                                name="noofseat"
                                fullWidth
                                style={{ marginTop: "20px" }}
                            /> */}
                            {/* </Box> */}
                        {/* </div> */}
                        {/* <div class="form-group col-md-6"> */}
                            {/* 
            <Box
                component="form"
                sx={{
                  '& > :not(style)': { mt:1,mb:1, width: '100%' },
                }}
                noValidate
                autoComplete="off"
              > */}
                            {/* <TextField id="outlined-basic" label="No of Private Cabins" variant="outlined" onChange={handlenoofcabins} value={noofcabins}
                                error={!!formErrors.noofcabins}
                                helperText={formErrors.noofcabins}
                                size="small"
                                fullWidth
                                name="noofprivate"
                                style={{ marginTop: "20px" }}
                            /> */}
                            {/* </Box> */}
                        {/* </div> */}
                    {/* </div> */}

                    <div class="">
                        <TextField className="descwidth"
                            placeholder="Description"
                            fullWidth
                            multiline
                            name="description"
                            sx={{
                                '& > :not(style)': { mt: 1, mb: 1, width: '100%', },
                            }}
                            rows={2}
                            variant='outlined'
                            maxRows={4}
                            style={{ marginTop: "20px"}}
                            onChange={handledescription} value={description}
                            // error={!!formErrors.description || spaceInterestedError1 === "true"}
                            helperText={formErrors.description}
                            InputProps={{
                                style: {
                                    color: spaceInterestedError1 === "true" ? "#d32f2f" : "" // Set the placeholder color to red if description is not given
                                }
                            }}
                        />
                    </div>
             
                    <div class="mt-10">
                        <span>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" 
                                    onChange={handleCheckboxChange} checked={isChecked}/>
                                    <p style={{ fontSize: "15px" }}> By Selecting this, you agree to our <a className='privatepolicy' href='/terms-conditions' target='blank'> Terms & Conditions</a></p>
                                    {/* <p style={{ fontSize: "15px" }}> By Selecting this, you agree to our <AnchorLink to="/terms-conditions" className='privatepolicy'> Terms & Conditions</AnchorLink></p> */}
                                {/* <p style={{ fontSize: "15px" }}> By Selecting this, you agree to our <span className='privatepolicy'> Terms & Conditions</span></p> */}
                            </div>
                        </span>
                        {checkboxError && (
                            <FormHelperText error>{checkboxError}</FormHelperText>
                        )}

                        {submissionStatus === 'success' && (
                            <FormHelperText style={{ color: 'green', fontSize:'16px', margin: '15px 0px'}}>Form submitted successfully!</FormHelperText>
                        )}
                       


                    </div>
                    <div class="mt-20">
                    <ReCAPTCHA sitekey={"6LeQCf0oAAAAAGbw4unaulP784of1ZMykUK_6dq-"} ref={captchaRef}  onChange={()=>RecaptchaChange()} 
                     />
                     <div style={{color:'#D32F2F', fontSize:"12px", fontFamily:'Roboto'}}> {captchaerror} </div>
                    </div>
                    <div class="mt-20">
                        <button type="submit" class="btn-primary" style={{ width: "270px" }}>Enquiry Now</button>

                    </div>

                </form>

            </div>
        </div>
    );
}

export default ContactForm;

